import {useMediaQuery} from "@mui/material";
import {useTheme} from "styled-components";

export const useBreakpoints = () => {
  const {breakpoints} = useTheme();
  return {
    upLg: useMediaQuery(breakpoints.up("lg")),
    upMd: useMediaQuery(breakpoints.up("md")),
    upSm: useMediaQuery(breakpoints.up("sm")),
    upXl: useMediaQuery(breakpoints.up("xl")),
    upXs: useMediaQuery(breakpoints.up("xs")),
    upXxl: useMediaQuery(breakpoints.up("xxl")),
    downLg: useMediaQuery(breakpoints.down("lg")),
    downMd: useMediaQuery(breakpoints.down("md")),
    downSm: useMediaQuery(breakpoints.down("sm")),
    downXl: useMediaQuery(breakpoints.down("xl")),
    downXxl: useMediaQuery(breakpoints.down("xxl")),
  };
};
