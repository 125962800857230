import {Container} from "./styled";
import {CSSProperties} from "react";
import {useTable} from "../../context";
import {Children, Clickable} from "interfaces";

interface TrProps extends Children, CSSProperties, Partial<Clickable> {}

export const Tr: React.FC<TrProps> = ({children, onClick, ...styles}) => {
  const {gridTemplateColumns} = useTable();
  return (
    <Container onClick={onClick} style={{...styles, gridTemplateColumns}}>
      {children}
    </Container>
  );
};
