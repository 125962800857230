import styled from "styled-components";

export const Container = styled.div`
  height: 50px;
  padding: 4px;
  width: 100%;
  display: flex;
  border-radius: 100px;
  flex-direction: row;
  background-color: #efefee;
`;

export const Switch = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
`;
