import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  border-bottom: 1px solid ${({theme}) => theme["line"]};
  &:hover {
    background-color: ${({theme}) => theme["hover"]}!important;
  }
  &:first-child {
    border-bottom: unset;
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    border-bottom: unset;
    border-radius: 0 0 5px 5px;
  }
`;
