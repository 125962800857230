import {useAppSelector} from "hooks";
import {ButtonLoaderId} from "interfaces";
import {useTheme} from "styled-components";
import {ColorRing} from "react-loader-spinner";
import {getSpinnerColors} from "../Button/helper";
import {Button, Container, ToolTip} from "./styled";
import {ButtonHTMLAttributes, CSSProperties, ReactNode} from "react";

export type ToolTipPosition = "up" | "down";
export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  icon: ReactNode;
  toolTip?: string;
  backgroundColor?: string;
  loaderId?: ButtonLoaderId;
  toolTipStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  toolTipPosition?: ToolTipPosition;
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const {isDark, white004, black004} = useTheme();
  const {
    icon,
    toolTip,
    loaderId,
    size = 35,
    toolTipStyle,
    containerStyle,
    disabled = false,
    toolTipPosition = "up",
    backgroundColor = isDark ? white004 : black004,
    ...buttonProps
  } = props;
  const loaderRingSize = size - 5;
  const {buttonLoaderIds} = useAppSelector("layout");
  const loaderColor = getSpinnerColors(backgroundColor);
  const loading = buttonLoaderIds["includes"](loaderId as ButtonLoaderId);

  return (
    <Container style={containerStyle}>
      <Button
        $darkMode={isDark}
        disabled={disabled || loading}
        $disabled={disabled || loading}
        $defaultColor={backgroundColor}
        {...buttonProps}
        style={{width: size, height: size, ...buttonProps["style"]}}
      >
        {loading ? (
          <ColorRing
            visible={loading}
            colors={loaderColor}
            width={loaderRingSize}
            height={loaderRingSize}
          />
        ) : (
          icon
        )}
      </Button>
      {toolTip && (
        <ToolTip
          className="tooltip"
          style={toolTipStyle}
          $toolTipPosition={toolTipPosition}
        >
          {toolTip}
        </ToolTip>
      )}
    </Container>
  );
};
