import {showAlert} from "./slices";
import {ShowAlertPayload} from "interfaces";
import {ThunkDispatch, UnknownAction} from "@reduxjs/toolkit";

type Error = {message: string};

export function thunkBadRequest(
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
  error: unknown,
  config: Omit<ShowAlertPayload, "title"> = {
    type: "error",
    message: undefined,
    durationMS: 5000,
  }
): void {
  dispatch(
    showAlert({
      title: (error as Error)["message"] ?? "Oh no, algo salió mal",
      ...config,
    })
  );
}
