import {go} from "..";
import {api} from "api";
import {toggleButtonLoaderId, close} from "../";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {
  Authenticated,
  ReferralCodePayload,
  BaseSignUpFormPayload,
} from "interfaces";

export const signUp = createAsyncThunk(
  "auth/sign-up",
  async (
    payload: BaseSignUpFormPayload & ReferralCodePayload,
    {rejectWithValue, dispatch}
  ) => {
    dispatch(toggleButtonLoaderId("sign-up"));

    try {
      const authenticated = await api.Post<Authenticated>(
        "/users/register",
        payload
      );
      thunkSuccess(dispatch, {
        title: "Cuenta creada con éxito",
        message: "Descarga nuestra App y comienza a participar",
      });
      dispatch(go("/"));
      dispatch(close());
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("sign-up"));
    }
  }
);
