import {ShowAlertPayload} from "interfaces";
import {initialState} from "./initial-state";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: function (state, action: PayloadAction<ShowAlertPayload>) {
      const {durationMS, message, title, type} = action["payload"];

      state["type"] = type;
      state["title"] = title;
      state["message"] = message;
      state["durationMS"] = durationMS ?? 5000;
      state["shown"] = true;
    },
    hideAlert: function (state) {
      state["shown"] = initialState["shown"];
    },
    resetAlert: function (state) {
      state["type"] = initialState["type"];
      state["title"] = initialState["title"];
      state["message"] = initialState["message"];
      state["durationMS"] = initialState["durationMS"];
    },
  },
});

export const {reducer: alert} = alertSlice;
export const {showAlert, hideAlert, resetAlert} = alertSlice["actions"];
