export function buildSelectOptions<
  TDataPager,
  KeyLabel extends keyof TDataPager,
  KeyValue extends keyof TDataPager
>(
  data: TDataPager[],
  placeholderLabel: string,
  {keyLabel, keyValue}: {keyLabel: KeyLabel; keyValue: KeyValue}
): {
  label: TDataPager[KeyLabel] | string;
  value: TDataPager[KeyValue] | string;
}[] {
  const defaultOption: ReturnType<typeof buildSelectOptions>[0] = {
    label: placeholderLabel,
    value: "",
  };
  return [
    defaultOption,
    ...data.map((obj) => ({
      label: obj[keyLabel],
      value: obj[keyValue],
    })),
  ];
}
