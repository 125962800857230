import {useCallback} from "react";
import {ModalConfig} from "interfaces";
import {close, open} from "@redux/slices";
import {useAppDispatch} from "./useAppDispatch";

export const useModal = () => {
  const dispatch = useAppDispatch();
  const openModal = useCallback(
    (element: JSX.Element, config?: Partial<ModalConfig>) => {
      dispatch(
        open({
          element,
          config,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const closeModal = useCallback(() => {
    dispatch(close());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {openModal, closeModal};
};
