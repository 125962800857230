import {Container} from "./styled";
import {Children, Clickable, Style} from "interfaces";
import {CSSProperties, ReactNode} from "react";

interface ColumnProps
  extends Children<ReactNode>,
    Style,
    CSSProperties,
    Partial<Clickable> {
  id?: string;
  className?: string;
}

export const Column: React.FC<ColumnProps> = (props) => {
  const {
    id,
    children,
    className,
    onClick,
    style: customStyle,
    ...style
  } = props;
  const {
    gap = 10,
    display = "flex",
    flexDirection = "column",
    alignItems = "flex-start",
  } = style;

  return (
    <Container
      id={id}
      onClick={onClick}
      children={children}
      className={className}
      style={{
        ...style,
        display,
        flexDirection,
        alignItems,
        gap,
        ...customStyle,
      }}
    />
  );
};
