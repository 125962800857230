export type LocalStorageKey = "[user]" | "[auth-token]";

export const storage = {
  set: (key: LocalStorageKey, value: any): void => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  read: <T>(key: LocalStorageKey): T | null => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  delete: <T>(key: LocalStorageKey): T | null => {
    const item = localStorage.getItem(key);
    localStorage.removeItem(key);
    return item ? JSON.parse(item) : null;
  },
};
