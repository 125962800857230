import {colors} from "styles";
import styled from "styled-components";
import {TypographyProps} from "../../@system/Typography";

export const Container = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
  flex-direction: column;
`;

export const InputStyled = styled.input`
  width: 100%;
  outline: none;
  border: unset;
  height: 57px;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 15px;
  font-family: inherit;
  background-color: unset;
  transition: all ease 0.5s;
  color: ${({theme}) => theme["inputTextColor"]};
  border: 2px solid ${({theme}) => theme["line"]};
  background-color: ${({theme}) => theme["container"]};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${({theme}) => theme["placeholderTextColor"]};
  }

  &:focus {
    border: 2px solid ${({theme}) => theme["primary"]};
    box-shadow: 0px 2px 3px 0px rgba(142, 207, 0, 0.2);
  }
  display: flex;
  display: -webkit-flex;
  flex: 1 0 0;
  -webkit-flex: 1 0 0;
  min-width: 95%;
`;

export const IconLabel = styled.label`
  top: 54%;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
`;

export const typographyErrorProps: Omit<TypographyProps, "children"> = {
  fontSize: 12,
  lineClamp: 1,
  width: "100%",
  textAlign: "end",
  marginBottom: -10,
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: colors["heart"],
  textOverflow: "ellipsis",
};
