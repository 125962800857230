import {Container} from "./styled";
import {CSSProperties} from "react";
import {Children} from "interfaces";

interface TdProps extends Children, CSSProperties {}

export const Td: React.FC<TdProps> = ({children, ...styles}) => {
  return (
    <Container className="table-td" style={styles}>
      {children}
    </Container>
  );
};
