import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";
import {getCountries, getStates, getCities} from "./thunks";

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(getCountries.fulfilled, function (state, action) {
      const {data} = action["payload"];
      state["countries"] = data;
      state["request"]["countries"] = setRequestStatus("success");
    });
    builder.addCase(getCountries.rejected, function (state) {
      state["request"]["countries"] = setRequestStatus("error");
    });

    builder.addCase(getStates.fulfilled, function (state, action) {
      const {data} = action["payload"];
      state["states"] = data;
      state["request"]["states"] = setRequestStatus("success");
    });
    builder.addCase(getStates.rejected, function (state) {
      state["request"]["states"] = setRequestStatus("error");
    });

    builder.addCase(getCities.fulfilled, function (state, action) {
      const {data} = action["payload"];
      state["cities"] = data;
      state["request"]["cities"] = setRequestStatus("success");
    });
    builder.addCase(getCities.rejected, function (state) {
      state["request"]["cities"] = setRequestStatus("error");
    });
  },
});

export const {reducer: location} = locationSlice;
