import {Td} from "../Td";
import {Container} from "./styled";
import {CSSProperties} from "react";
import {useTable} from "../../context";
import {Typography, TypographyProps} from "components/@system/Typography";

export interface THeadProps {
  headStyle?: CSSProperties;
  head: {label: string; typographyProps?: Omit<TypographyProps, "children">}[];
}

export const THead: React.FC<THeadProps> = ({head, headStyle}) => {
  const {gridTemplateColumns} = useTable();

  return (
    <Container style={{gridTemplateColumns, ...headStyle}}>
      {head.map(({label, typographyProps}, key) => (
        <Td key={key}>
          <Typography
            fontSize={16}
            fontFamily="Poppins-Medium"
            {...typographyProps}
          >
            {label}
          </Typography>
        </Td>
      ))}
    </Container>
  );
};
