import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 42px;
  padding: 2px 0;
  overflow: hidden;
  border-radius: 100;
  position: relative;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
`;

export const Progress = styled.div`
  height: 100%;
  border-radius: 100px;
`;
