import {Typography} from "../..";
import {typographyErrorProps} from "../Input/styled";
import {Container, SelectStyled, Option} from "./styled";
import {CSSProperties, SelectHTMLAttributes} from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  touched?: boolean;
  helperErrorText?: string;
  containerStyle?: CSSProperties;
  options: {value: any; label: string}[];
}

export const Select: React.FC<SelectProps> = (props) => {
  const {
    label,
    touched,
    options,
    containerStyle,
    helperErrorText,
    ...selectProps
  } = props;

  return (
    <Container style={containerStyle}>
      {label && <Typography fontWeight="500">{label}</Typography>}
      <SelectStyled
        {...selectProps}
        children={options.map(({label, value}, key) => (
          <Option key={key} value={value}>
            {label}
          </Option>
        ))}
      />
      {helperErrorText && touched && (
        <Typography {...typographyErrorProps}>{helperErrorText}</Typography>
      )}
    </Container>
  );
};
