import {Global} from "components";
import {buildTheme} from "styles";
import {Navigation} from "routing";
import {store} from "@redux/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import "react-loading-skeleton/dist/skeleton.css";
import "react-circular-progressbar/dist/styles.css";

function App() {
  const theme = buildTheme({isDark: false, mode: "light"});

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Global />
          <Navigation />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
