import {ToolTipPosition} from ".";
import styled from "styled-components";
import {pickButtonColor} from "styles";

export const Container = styled.div`
  position: relative;
  ${({theme}) => theme.breakpoints.up("sm")} {
    &:hover {
      .tooltip {
        opacity: 1;
        z-index: 999;
        display: flex;
      }
    }
  }
`;

export const Button = styled.button<{
  $darkMode: boolean;
  $disabled: boolean;
  $defaultColor: string;
}>`
  border: unset;
  display: flex;
  outline: unset;
  cursor: ${({$disabled}) => ($disabled ? "unset" : "pointer")};
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  transition: all ease 0.3s;
  background-color: ${(props) =>
    pickButtonColor(
      props["$disabled"],
      props["$darkMode"],
      props["$defaultColor"]
    )};

  &:active {
    transform: scale(0.9);
  }
`;

export const ToolTip = styled.span<{$toolTipPosition: ToolTipPosition}>`
  left: 50%;
  opacity: 0;
  z-index: -1;
  display: none;
  font-size: 13px;
  width: max-content;
  position: absolute;
  text-align: center;
  align-items: center;
  border-radius: 10rem;
  padding: 0.4rem 0.6rem;
  justify-content: center;
  transition: all ease 0.2s;
  transform: translateX(-50%);
  color: ${({theme}) => theme["white"]};
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) => theme["primary"]};
  top: ${({$toolTipPosition}) =>
    $toolTipPosition === "up" ? "-2.2rem" : "2.7rem"};
`;
