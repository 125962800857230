import {Style} from "interfaces";
import {Container} from "./styled";
import {TableContext} from "./context";
import {THead, THeadProps, Td, Tr} from "./lib";
import {CSSProperties, Fragment, ReactNode} from "react";
import {Typography, Column, EmptyUIState, EmptyUIStateProps} from "../..";

interface TableProps<T> extends THeadProps, Style {
  data: T[];
  title?: string;
  columns?: string;
  className?: string;
  header?: JSX.Element;
  headStyle?: CSSProperties;
  children: (dataRow: T, key: number) => ReactNode;
  emptyUI?: Omit<EmptyUIStateProps, "data" | "children">;
}

export const Table = <T,>(props: TableProps<T>) => {
  const {
    data,
    head,
    style,
    title,
    header,
    columns,
    emptyUI,
    children,
    headStyle,
    className,
  } = props;

  return (
    <Column width="100%" gap={title ? 5 : 0}>
      {header && header}
      {title && <Typography variant="title">{title}</Typography>}
      <TableContext.Provider
        value={{gridTemplateColumns: columns ?? `repeat(${head.length}, 1fr)`}}
      >
        <Container style={style} className={className}>
          <THead head={head} headStyle={headStyle} />
          <EmptyUIState {...emptyUI} data={data}>
            {data.map((row, key) => (
              <Fragment key={key}>{children(row, key)}</Fragment>
            ))}
          </EmptyUIState>
        </Container>
      </TableContext.Provider>
    </Column>
  );
};

Table.Tr = Tr;
Table.Td = Td;
