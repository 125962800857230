import {configureStore} from "@reduxjs/toolkit";
import {alert, modal, layout, location, navigation} from "./slices";

export const store = configureStore({
  reducer: {
    modal,
    alert,
    layout,
    location,
    navigation,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
