import {Route} from "interfaces";
import {Home, Page404, Register} from "pages";

export const routes: Route[] = [
  {
    path: "/",
    element: <Home />,
    label: "Inicio",
  },
  {
    path: "/register",
    element: <Register />,
    label: "Registro",
  },

  /************************************
   * 🛑 404
   ************************************/
  {
    path: "*",
    element: <Page404 />,
    label: "404",
  },
];
