import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 100vh;
  grid-template-columns: 1fr;

  ${({theme}) => theme.breakpoints.up("lg")} {
    grid-template-columns: 1.2fr 1fr;
  }
  ${({theme}) => theme.breakpoints.up("xxl")} {
    grid-template-columns: 2fr 1fr;
  }
`;

export const SideBrand = styled.div`
  gap: 1rem;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({theme}) => theme["primary"]};
  ${({theme}) => theme.breakpoints.up("lg")} {
    display: flex;
  }
`;

export const Image = styled.img`
  width: 40px;
  object-fit: contain;
`;

export const Stores = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({theme}) => theme["primary"]};
  padding-top: 5rem;
  ${({theme}) => theme.breakpoints.up("lg")} {
    padding-top: unset;
    justify-content: center;
    background-color: ${({theme}) => theme["white"]};
  }
`;
