import {motion} from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;
