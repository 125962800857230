import {api} from "api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {CityModel, CountryModel, StateModel} from "interfaces";

export const getCountries = createAsyncThunk(
  "location/get-countries",
  async (_, {rejectWithValue, dispatch}) => {
    try {
      return await api.Get<CountryModel[]>(
        "/geographical-division/country/getAll?limit=100&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getStates = createAsyncThunk(
  "location/get-states",
  async (countryId: string, {rejectWithValue, dispatch}) => {
    try {
      return await api.Get<StateModel[]>(
        `/geographical-division/state/getAll/${countryId}?limit=100&position=0&search=`
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getCities = createAsyncThunk(
  "location/get-cities",
  async (stateId: string, {rejectWithValue, dispatch}) => {
    try {
      return await api.Get<CityModel[]>(
        `/geographical-division/city/getAll/${stateId}?limit=10&position=0&search=`
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
