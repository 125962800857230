import {useEffect} from "react";
import {Fragment} from "react/jsx-runtime";
import {clearRedirect} from "@redux/slices";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";

interface ReduxNavigationProps {}

export const ReduxNavigation: React.FC<ReduxNavigationProps> = () => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {redirect} = useAppSelector("navigation");

  useEffect(() => {
    if (redirect) {
      push(redirect);
      dispatch(clearRedirect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  return <Fragment />;
};
