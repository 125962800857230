import {Helmet} from "react-helmet";
import {useAppSelector} from "hooks";

interface StatusBarProps {}

export const StatusBar: React.FC<StatusBarProps> = () => {
  const {statusBarColor} = useAppSelector("layout");

  return (
    <Helmet>
      <meta name="theme-color" content={statusBarColor} />
    </Helmet>
  );
};
