import {showAlert} from "./slices";
import {ThunkDispatch, UnknownAction} from "@reduxjs/toolkit";

export function thunkSuccess(
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
  {title, message = undefined}: {title: string; message?: string}
): void {
  dispatch(
    showAlert({
      title,
      message,
      type: "success",
      durationMS: 5000,
    })
  );
}
