import moment from "moment";
import {Formik} from "formik";
import {useEffect} from "react";
import {Container} from "./styled";
import {buildSelectOptions} from "utils";
import {useTheme} from "styled-components";
import {Check, ChevronLeft} from "react-feather";
import {signUp} from "@redux/slices/auth/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {initialValues, signUpSchema} from "./form.schema";
import {useLocation, useNavigate} from "react-router-dom";
import {
  getStates,
  getCities,
  getCountries,
} from "@redux/slices/location/thunks";
import {
  Row,
  Logo,
  Line,
  Input,
  Column,
  Button,
  Select,
  IconButton,
  Typography,
} from "components";
import {showAlert} from "@redux/slices";

interface RegisterProps {}

export const Register: React.FC<RegisterProps> = () => {
  const push = useNavigate();
  const {search} = useLocation();
  const dispatch = useAppDispatch();
  const {primary, white} = useTheme();
  const referralCode: string | undefined = search.split("=")[1];
  const {countries, states, cities} = useAppSelector("location");

  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row marginBottom={10} gap={10} paddingLeft={15}>
        <IconButton
          onClick={() => push(-1)}
          icon={<ChevronLeft />}
          style={{borderRadius: 100}}
        />
        <Column gap={5}>
          <Logo width={100} variant="dark" />
          <Typography fontSize={12} fontFamily="Poppins-Medium">
            Gana, sueña y emprende
          </Typography>
        </Column>
      </Row>
      <Formik
        validateOnChange={true}
        validationSchema={signUpSchema}
        initialValues={{...initialValues, referralCode}}
        onSubmit={(payload) => {
          dispatch(signUp(payload));
        }}
      >
        {({
          dirty,
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const disabled = !(dirty && isValid);

          return (
            <Column width="100%" padding="0 15px">
              <Column gap={0} width="100%">
                <Input
                  autoFocus
                  name="name"
                  label="Nombre"
                  placeholder="Centro de"
                  value={values["name"]}
                  touched={touched["name"]}
                  onBlur={handleBlur("name")}
                  helperErrorText={errors["name"]}
                  onChange={handleChange("name")}
                />
                <Input
                  name="lastname"
                  label="Apellido"
                  placeholder="Apuestas"
                  value={values["lastname"]}
                  touched={touched["lastname"]}
                  onBlur={handleBlur("lastname")}
                  helperErrorText={errors["lastname"]}
                  onChange={handleChange("lastname")}
                />
                <Input
                  name="password"
                  type="password"
                  label="Contraseña"
                  placeholder="*********"
                  value={values["password"]}
                  touched={touched["password"]}
                  onBlur={handleBlur("password")}
                  helperErrorText={errors["password"]}
                  onChange={handleChange("password")}
                />
                <Input
                  name="email"
                  label="Correo"
                  inputMode="email"
                  autoComplete="off"
                  autoCapitalize="none"
                  value={values["email"]}
                  placeholder="tu@correo.com"
                  touched={touched["email"]}
                  onBlur={handleBlur("email")}
                  helperErrorText={errors["email"]}
                  onChange={handleChange("email")}
                />
                <Input
                  name="phone"
                  inputMode="numeric"
                  label="Teléfono móvil"
                  value={values["phone"]}
                  touched={touched["phone"]}
                  placeholder="04242223344"
                  onBlur={handleBlur("phone")}
                  helperErrorText={errors["phone"]}
                  onChange={handleChange("phone")}
                />
                <Input
                  type="date"
                  name="birthday"
                  placeholder="DD/MM/AAAA"
                  value={values["birthday"]}
                  label="Fecha de nacimiento"
                  onBlur={handleBlur("birthday")}
                  onChange={handleChange("birthday")}
                  helperErrorText={errors["birthday"]}
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                />
                <Select
                  label="País"
                  name="countryId"
                  touched={touched["countryId"]}
                  onBlur={handleBlur("countryId")}
                  helperErrorText={errors["countryId"]}
                  value={values["countryId"] as string}
                  onChange={({target}) => {
                    const {value} = target;
                    dispatch(getStates(value as string));
                    handleChange({
                      target: {name: "countryId", value},
                    });
                  }}
                  options={buildSelectOptions(countries, "Selecciona tu país", {
                    keyLabel: "name",
                    keyValue: "_id",
                  })}
                />
                <Select
                  label="Estado"
                  name="stateId"
                  touched={touched["stateId"]}
                  onBlur={handleBlur("stateId")}
                  helperErrorText={errors["stateId"]}
                  value={values["stateId"] as string}
                  onChange={({target}) => {
                    const {value} = target;
                    dispatch(getCities(value as string));
                    handleChange({target: {name: "stateId", value}});
                  }}
                  options={buildSelectOptions(states, "Selecciona tu estado", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
                <Select
                  label="Ciudad"
                  name="cityId"
                  touched={touched["cityId"]}
                  onBlur={handleBlur("cityId")}
                  helperErrorText={errors["cityId"]}
                  value={values["cityId"] as string}
                  onChange={({target}) => {
                    const {value} = target;
                    handleChange({target: {name: "cityId", value}});
                  }}
                  options={buildSelectOptions(cities, "Selecciona tu ciudad", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
                <Line />
                <Column width="100%" gap={0}>
                  <Input
                    name="referralCode"
                    placeholder="HS2024"
                    label="Código de referido"
                    value={values["referralCode"]}
                    touched={touched["referralCode"]}
                    onBlur={handleBlur("referralCode")}
                    helperErrorText={errors["referralCode"]}
                    onChange={handleChange("referralCode")}
                  />
                  {values["referralCode"] && (
                    <Row marginBottom={10}>
                      <IconButton
                        size={25}
                        backgroundColor={primary}
                        style={{borderRadius: 100}}
                        icon={<Check size={18} color={white} />}
                      />
                      <Typography fontSize={14} fontFamily="Poppins-Medium">
                        Código de referido agregado con éxito!
                      </Typography>
                    </Row>
                  )}
                </Column>
              </Column>
              <Button
                loaderId="sign-up"
                disabled={disabled}
                label=" Registrarse"
                style={{width: "100%"}}
                onClick={() => handleSubmit()}
              />
            </Column>
          );
        }}
      </Formik>
    </Container>
  );
};
