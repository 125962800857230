import {ArrowLeft} from "react-feather";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Button, Column, Row, Typography} from "components";

interface Page404Props {}

export const Page404: React.FC<Page404Props> = () => {
  const push = useNavigate();
  const {white} = useTheme();

  return (
    <Column gap={0} alignItems="center" padding={"4rem 1rem"}>
      <Row gap={20} padding={10} borderRadius={10}>
        <Typography fontSize={40} fontFamily="Poppins-SemiBold">
          404
        </Typography>
      </Row>
      <Typography textAlign="center" color="#444444" fontSize={18}>
        La página que intentas acceder no existe
      </Typography>
      <Button
        label="Ir al inicio"
        style={{marginTop: 20}}
        onClick={() => push("/")}
        leftIcon={<ArrowLeft size={18} color={white} />}
      />
    </Column>
  );
};
