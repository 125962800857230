import {Fragment} from "react/jsx-runtime";
import {
  Modal,
  Alert,
  Overlap,
  StatusBar,
  AutoScrollTop,
  ReduxNavigation,
  FullScreenLoader,
} from "components";

interface GlobalProps {}

export const Global: React.FC<GlobalProps> = () => {
  return (
    <Fragment>
      <Alert />
      <Modal />
      <Overlap />
      <StatusBar />
      <AutoScrollTop />
      <ReduxNavigation />
      {/* <FullScreenLoader /> */}
    </Fragment>
  );
};
