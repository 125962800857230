import {useEffect} from "react";
import {Container} from "./styled";
import {useAppSelector} from "hooks";
import {toggleBodyScroll} from "utils";
import {Backdrop} from "components/@system";
import {AnimatePresence} from "framer-motion";

interface OverlapProps {
  onClick?: () => void;
  backgroundColor?: string;
}

export const Overlap: React.FC<OverlapProps> = ({
  onClick,
  backgroundColor = "rgba(0,0,0, .35)",
}) => {
  const {showOverlap} = useAppSelector("layout");
  useEffect(() => {
    toggleBodyScroll(showOverlap);
  }, [showOverlap]);

  return (
    <AnimatePresence>
      {showOverlap && (
        <Container
          exit={{opacity: 0}}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          style={{backgroundColor}}
        >
          <Backdrop onClick={onClick} />
        </Container>
      )}
    </AnimatePresence>
  );
};
