import moment from "moment";

export const dateDiff = (startDate: Date): string => {
  const now = moment(new Date());
  var duration = moment.duration(now.diff(startDate));

  const days = duration.days();
  duration.subtract(days, "days");

  const hours = duration.hours();
  duration.subtract(hours, "hours");

  const minutes = duration.minutes();
  duration.subtract(minutes, "minutes");

  const seconds = duration.seconds();

  let displayDiff = `${days > 0 ? `${days} días ` : ""}${
    hours > 0 ? `${hours} horas ` : ""
  }${minutes > 0 ? `y ${minutes} minutos` : ""}`;

  if (days <= 0 && hours <= 0 && minutes <= 0) {
    displayDiff = `${seconds} segundos`;
  }

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    displayDiff = `No esta activo`;
  }

  return displayDiff;
};
