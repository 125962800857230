import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  height: 35px;
  padding: 0 0.8rem;
  justify-content: center;
  background-color: #46328d;
`;
