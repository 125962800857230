import styled from "styled-components";

export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  gap: 10px;
  position: fixed;
  z-index: 9999999999;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({theme}) => theme["primary"]};
`;
