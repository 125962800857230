import {User} from "react-feather";
import {useBreakpoints} from "hooks";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Container, SideBrand, Image, Stores} from "./styled";
import {Button, Chip, Column, Line, Logo, Row, Typography} from "components";

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const push = useNavigate();
  const {upLg} = useBreakpoints();
  const {white, black, primary, secondary} = useTheme();
  const textColor = upLg ? black : white;
  const backgroundButtonColor = upLg ? primary : secondary;
  return (
    <Container>
      <SideBrand>
        <Logo width={upLg ? 250 : 150} />
        <Typography fontSize={18} color={white} fontFamily="Poppins-Medium">
          Gana, sueña y emprende
        </Typography>
      </SideBrand>
      <Stores>
        <Column alignItems="center">
          <Logo width={upLg ? 120 : 150} variant={upLg ? "dark" : "light"} />
          <Column gap={5}>
            <Typography color={textColor}>
              ¿No tienes una cuenta aún?
            </Typography>
            <Button
              label="Crear una cuenta"
              onClick={() => push("/register")}
              leftIcon={<User color={white} />}
              style={{backgroundColor: backgroundButtonColor}}
            />
          </Column>
        </Column>
        <Line margin="1.5rem 0" />
        <Column alignItems="center">
          <Chip>
            <Typography fontSize={14} color={white}>
              Descarga nuestra App
            </Typography>
          </Chip>
          <Row gap={20}>
            <a href="#">
              <Image src="/assets/stores/app-store.svg" />
            </a>
            <a href="#">
              <Image src="/assets/stores/google-play.svg" />
            </a>
          </Row>
          <Typography fontSize={14} color={textColor} textAlign="center">
            y comienza a ganar premios en cada sorteo
          </Typography>
        </Column>
      </Stores>
    </Container>
  );
};
