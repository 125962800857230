import {createStyledBreakpointsTheme} from "styled-breakpoints";

export const breakpointsTheme = createStyledBreakpointsTheme();

/**
 * xs: '0px',
 * sm: '576px',
 * md: '768px',
 * lg: '992px',
 * xl: '1200px',
 * xxl: '1400px',
 * 
 ${({theme}) => theme.breakpoints.up("sm")} {
    
  }
 */
