import {useEffect} from "react";
import {useModal, useAppSelector} from "hooks";

export const useKeydown = () => {
  const {closeModal} = useModal();
  const {activeModal} = useAppSelector("modal");

  useEffect(() => {
    if (typeof activeModal === "undefined") return;
    const {closeKey} = activeModal["config"];
    if (typeof closeKey === "undefined") return;

    window.addEventListener("keydown", (event) => {
      switch (event["key"]) {
        case closeKey:
          closeModal();
          break;
      }
    });

    return () => {
      window.removeEventListener("keydown", () => undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModal]);
};
