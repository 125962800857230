import * as Yup from "yup";
import moment from "moment";
import {BaseSignUpFormPayload, ReferralCodePayload} from "interfaces";

export const initialValues: BaseSignUpFormPayload & ReferralCodePayload = {
  name: "",
  email: "",
  phone: "",
  cityId: "",
  stateId: "",
  password: "",
  lastname: "",
  countryId: "",
  referralCode: "",
  birthday: moment().subtract(18, "years").format("YYYY-MM-DD"),
};

export const signUpSchema = Yup.object().shape({
  name: Yup.string().required("Tu nombre es requerido"),
  cityId: Yup.string().required("Este campo es requerido"),
  stateId: Yup.string().required("Este campo es requerido"),
  countryId: Yup.string().required("Este campo es requerido"),
  lastname: Yup.string().required("Tu apellido es requerido"),
  password: Yup.string().required("La contraseña es requerida"),
  birthday: Yup.string().required("Tu fecha de nacimiento es requerida"),
  phone: Yup.string()
    .required("Tu teléfono es requerido")
    .matches(
      /^(0414|0424|0412|0426|0416)\d{7}$/,
      "Debe ser un número de teléfono válido, ej: 04129998899"
    ),
  email: Yup.string()
    .email("Debe ser un correo válido")
    .matches(/@[^.]*\./, "Debe ser un correo válido")
    .required("El correo es requerido"),
});
