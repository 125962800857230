import {colors} from "styles";
import styled from "styled-components";
import {TypographyProps} from "../../@system/Typography";

export const Container = styled.div`
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
  flex-direction: column;
`;

export const TextareaStyled = styled.textarea`
  width: 100%;
  resize: none;
  outline: none;
  border: unset;
  font-size: 1rem;
  padding: 0.8rem;
  font-weight: 400;
  border-radius: 5px;
  font-family: inherit;
  background-color: unset;
  transition: all ease 0.5s;
  border: 2px solid ${({theme}) => theme["line"]};
  background-color: ${({theme}) => theme["container"]};
  color: ${(props) =>
    props["theme"]["isDark"] ? colors["white"] : colors["black"]};
  &::placeholder {
    color: ${(props) =>
      props["theme"]["isDark"]
        ? "rgba(255, 255, 255, .5)"
        : "rgba(0, 0, 0, .5)"};
  }

  &:focus {
    border: 2px solid ${({theme}) => theme["primary"]};
    box-shadow: 0px 1.5px 3px 0px rgba(142, 207, 0, 0.2);
  }
`;

export const typographyErrorProps: Omit<TypographyProps, "children"> = {
  right: 0,
  bottom: -22,
  fontSize: 12,
  lineClamp: 1,
  width: "100%",
  textAlign: "end",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  color: colors["heart"],
  textOverflow: "ellipsis",
};
