import {routes} from "./config";
import {Route, Routes} from "react-router-dom";

interface NavigationProps {}

export const Navigation: React.FC<NavigationProps> = () => {
  return (
    <Routes
      children={routes.map(({path, element}, key) => (
        <Route key={key} path={path} element={element} />
      ))}
    />
  );
};
