import styled from "styled-components";

export const Container = styled.div`
  gap: 5px;
  height: 40px;
  display: flex;
  padding: 0 15px;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  background-color: #000;
`;
