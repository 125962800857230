import {Pathname} from "interfaces";
import {initialState} from "./initial-state";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    go: function (state, action: PayloadAction<Pathname>) {
      state["redirect"] = action["payload"];
    },
    clearRedirect: function (state) {
      state["redirect"] = undefined;
    },
  },
});

export const {reducer: navigation} = navigationSlice;
export const {go, clearRedirect} = navigationSlice["actions"];
