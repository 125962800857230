import {useEffect} from "react";
import {Fragment} from "react/jsx-runtime";
import {useLocation} from "react-router-dom";

interface AutoScrollTopProps {}

export const AutoScrollTop: React.FC<AutoScrollTopProps> = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo({top: 0});
  }, [pathname]);

  return <Fragment />;
};
