import {motion} from "framer-motion";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const List = styled(motion.div)`
  gap: 2px;
  top: 50px;
  z-index: 99;
  display: flex;
  padding: 0.5rem;
  border-radius: 5px;
  position: absolute;
  flex-direction: column;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) => theme["container"]};
`;

export const ListItem = styled.div`
  display: grid;
  cursor: pointer;
  align-items: center;
  border-radius: 5px;
  padding: 1rem 0.5rem;
  &:hover {
    background-color: ${({theme}) => theme["hover"]};
  }
  &:active {
    transform: scale(0.99);
  }
`;
