import {colors} from "styles";
import {Container} from "./styled";
import {Inbox} from "react-feather";
import {Typography} from "../Typography";
import {Fragment, ReactNode} from "react";
import {Children, Style} from "interfaces";

export interface EmptyUIStateProps extends Style, Children {
  data: any[];
  icon?: ReactNode;
  body?: ReactNode;
  message?: string;
}

export const EmptyUIState: React.FC<EmptyUIStateProps> = ({
  icon,
  body,
  data,
  style,
  message,
  children,
}) => {
  return !data.length ? (
    <Container style={style}>
      {icon ?? <Inbox size={40} color={colors["black"]} />}
      <Typography fontSize={15}>{message ?? "Nada que mostrar aún"}</Typography>
      {body && body}
    </Container>
  ) : (
    <Fragment>{children}</Fragment>
  );
};
