import {initialState} from "./initial-state";
import {Modal, ModalConfig} from "interfaces";
import {DEFAULT_CONFIG} from "./default-values";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    open: function (
      state,
      action: PayloadAction<{
        element: JSX.Element;
        config?: Partial<ModalConfig>;
      }>
    ) {
      const {element, config} = action["payload"];
      const newModal: Modal = {
        element,
        config: Object.assign({}, DEFAULT_CONFIG, config),
      };
      state["stack"] = [...state["stack"], newModal];
      state["activeModal"] = newModal;
    },

    close: function (state) {
      const stack = state["stack"];
      const removed = stack.slice(-1)[0];
      if (removed) {
        const {onBackdrop, onClosed} = removed["config"];
        if (onClosed) onClosed();
        if (onBackdrop) onBackdrop();
      }
      if (stack.length > 1) {
        const temp = [...stack];
        state["activeModal"] = temp[stack.length - 2];
        temp.pop();
        state["stack"] = temp;
      } else {
        state["stack"] = [];
        state["activeModal"] = undefined;
      }
    },
  },
});

export const {reducer: modal} = modalSlice;
export const {open, close} = modalSlice["actions"];
