import {Container} from "./styled";
import {Children, Clickable} from "interfaces";
import {CSSProperties, ReactNode} from "react";

interface RowProps
  extends Children<ReactNode>,
    CSSProperties,
    Partial<Clickable> {}

export const Row: React.FC<RowProps> = (props) => {
  const {children, onClick, ...style} = props;
  const {display = "flex", alignItems = "center", gap = 5} = style;
  return (
    <Container
      onClick={onClick}
      children={children}
      style={{...style, display, alignItems, gap}}
    />
  );
};
